'use client';

import React, { useState } from 'react';
import { Spinner } from '@/components/Loadings';
import clsx from 'clsx';
import './index.scss';
import dynamic from 'next/dynamic';

interface IProps {
  src: string;
  className?: string;
  innerClassName?: string;
  isPlaying?: boolean;
  isVideoRounded?: boolean;
  objectPositionRight?: boolean;
}

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export const LoopingVideo = ({
  src,
  className,
  innerClassName,
  isPlaying = false,
  isVideoRounded = false,
  objectPositionRight = false,
}: IProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <div
      data-testid="loop-video"
      className={clsx(
        `relative ${isVideoLoaded ? 'bg-transparent' : 'bg-black shadow-2xl'} group w-[calc(100%-30px)] h-full 
        rounded-[8px] container mx-auto my-[10px] max-w-[1376px] p-0 react-player z-10
        aspect-video`,
        {
          [`${className}`]: className,
        },
      )}
    >
      <div
        className={clsx('relative flex items-center justify-center w-full h-full aspect-video', {
          ['hidden']: !isVideoLoaded,
          [`${innerClassName}`]: !!innerClassName,
        })}
      >
        <ReactPlayer
          className={clsx('absolute w-full h-full aspect-video react-player object-fit-cover pointer-events-none', {
            ['rounded']: isVideoRounded,
            ['object-position-right']: objectPositionRight,
          })}
          url={src}
          playing={isPlaying}
          loop
          controls={false}
          width="100%"
          height="100%"
          muted
          playsinline
          stopOnUnmount
          onReady={() => {
            if (isVideoLoaded) {
              return;
            }

            setIsVideoLoaded(true);
          }}
        />
      </div>
      <div
        className={clsx('relative transform mx-auto w-full h-full', {
          ['hidden']: isVideoLoaded,
        })}
      >
        <Spinner />
      </div>
    </div>
  );
};
